<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>建设用地规划项目</span>
        <el-button type="primary" v-show="!isDisabled" @click="onSubmit"
          >保存</el-button
        >
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="案卷编号:">
                <el-input disabled v-model="form.AJBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目代码:">
                <el-input disabled v-model="form.XMDM"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="项目编号:">
                <el-input :disabled="isDisabled" v-model="form.XMBH"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="证书编号:">
                <el-input :disabled="isDisabled" v-model="form.ZSBH"></el-input>
              </el-form-item>
              <el-form-item label="发证日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FZRQ"
                  placeholder="请选择发证日期"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  :disabled="isDisabled"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="用地单位:">
                <el-input :disabled="isDisabled" v-model="form.YDDW"></el-input>
              </el-form-item>
              <el-form-item label="项目名称:">
                <el-input :disabled="isDisabled" v-model="form.XMMC"></el-input>
              </el-form-item>
              <el-form-item label="用地位置:">
                <el-input :disabled="isDisabled" v-model="form.YDWZ"></el-input>
              </el-form-item>
              <el-form-item label="用地性质:">
                <el-input :disabled="isDisabled" v-model="form.YDXX"></el-input>
              </el-form-item>
              <el-form-item label="用地面积:">
                <el-input :disabled="isDisabled" v-model="form.YDMJ">
                  <template #append>平方米</template>
                </el-input>
              </el-form-item>
              <el-form-item label="建设规模:">
                <el-input :disabled="isDisabled" v-model="form.JSGM"></el-input>
              </el-form-item>
              <el-form-item label="土地取得方式:">
                <el-input :disabled="isDisabled" v-model="form.QDFS"></el-input>
              </el-form-item>
              <el-form-item label="业务年份:">
                <el-input :disabled="isDisabled" v-model="form.YWNF"></el-input>
              </el-form-item>
              <el-form-item label="用地代码:">
                <el-input :disabled="isDisabled" v-model="form.YDDM"> </el-input>
              </el-form-item>
              <el-form-item label="工程类型:">
                <el-input :disabled="isDisabled" v-model="form.GCLX"> </el-input>
              </el-form-item>
              <el-form-item label="业务局:">
                <el-input :disabled="isDisabled" v-model="form.YWJ"> </el-input>
              </el-form-item>

            </el-col>
            <el-col :span="12">
              <el-form-item label="经办人姓名:">
                <el-input v-model="form.JBRXM" disabled> </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经办时间:">
                <el-date-picker style="width: 100%" disabled v-model="form.JBSJ">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注:">
                <el-input :disabled="isDisabled"
                  v-model="form.BZ"
                  type="textarea"
                  :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  name:'jsydghxm',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: "",
        BJID: "",
        XMBH: "",
        XMDM: "",
        AJBH: "",
        ZSBH: "",
        FZRQ: "",
        YDDW: "",
        XMMC: "",
        YDWZ: "",
        YDXX: "",
        YDMJ: "",
        JSGM: "",
        QDFS: "",
        YWNF: "",
        YDDM: "",
        GCLX: "",
        YWJ: "",
        JBR: "",
        JBRXM: "",
        JBSJ: new Date(),
        QLSJ: "",
        QLRY: "",
        ZJSJ: "",
        ZJRY: "",
        ZJYJ: "",
        BZ: ""

      },
      isDisabled: true,
    };
  },
  mounted () {
    this.switch();
  },
  methods: {
    async switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId
      await this.$ajax
        .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getjsydghxm?BJID=" + me.form.BJID)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form = response.data.Data;
            if(response.data.Data.Id===null){
              me.form.JBRXM=me.$router.user.profile.name;
              me.form.JBSJ=new Date();
              me.form.QLSJ=null;
              me.form.ZJSJ=null;
            }
            me.form.AJBH = me.$store.state.AJH;
            me.form.XMDM = me.$store.state.XMDM;
          } else {
            ElMessage({
              type: "info",
              message: "初始化表单失败",
            });
          }
        });
        // if (this.$route.query.taskId) {
        if (this.form.JBR === this.$router.user.profile.sub && this.$route.query.taskId) {
          this.isDisabled = false;
        }
    },
    async onSubmit() {
      let me = this
      me.form.JBRXM = me.$router.user.profile.name;
      me.form.JBSJ = new Date();
      
      await this.$ajax
        .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatejsydghxm", me.form)
        .then(function (response) {
          if (response.data.IsSuccess) {
            me.form.Id = response.data.Data;
            ElMessage({
              type: "success",
              message: "保存成功",
            });
          } else {
            ElMessage({
              type: "info",
              message: "保存失败!",
            });
            console.log(response.data.errorMessage);
          }
        });

        console.log(this.form);
      
    },
    checkform() {
      let data = [ 'XMBH', 'XMDM', 'AJBH', 'ZSBH', 'FZRQ', 'YDDW', 'XMMC', 'YDWZ'
      , 'YDXX', 'YDMJ', 'JSGM', 'QDFS', 'YWNF', 'YDDM', 'GCLX', 'YWJ', 'JBRXM'
      , 'JBSJ', 'QLSJ', 'QLRY', 'ZJSJ', 'ZJRY', 'ZJYJ', 'BZ']
      let labelList = [ '项目编号', '项目代码', '案卷编号', '证书编号', '发证日期', '用地单位', '项目名称' , '用地位置'
      , '用地性质', '用地面积', '建设规模', '土地取得方式', '业务年份', '用地代码', '工程类型', '业务局', '经办人姓名'
      , '经办时间', '清理时间', '清理人员', '质检时间', '质检人员', '质检意见', '备注']
      let r = -1;
      for (let i = 0; i < data.length; i++) {
        let key = data[i]
        if (this.form[key] == ''||this.form[key] == null) {
          r = i;
          break;
        } else if (i === 10) {
          let checkNum = /^[0-9]*$/
          if (!checkNum.test(this.form.YDMJ)) {
            ElMessage({
              type: 'info',
              message: '用地面积请输入数字',
            })
            return false;
          }
        }
      }
      if (r == -1) {
        return true
      } else {
        ElMessage({
          type: 'info',
          message: '请输入' + labelList[r],
        })
        return false;
      }
    }
  }
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
